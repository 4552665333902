
import { defineComponent } from 'vue'
export default defineComponent({
    props: {
        baseValue: {
            type: Number,
            default: 0,
            required: true,
        },
        refillValue: {
            type: Number,
            default: 0,
            required: true,
        },
        currentValue: {
            type: Number,
            default: 0,
            required: true,
        },
        liters: {
            type: Number,
            default: 0,
            required: true,
        },
        needIntervention: {
            type: Boolean,
            default: false,
            required: false,
        },
    },
    setup(props) {
        const refillComputed = props.refillValue + props.currentValue
        return {
            refillComputed,
        }
    },
})
