
import CircleGraph from '@/components/CircleGraph.vue'
import { useCarWash } from '@/composables/useCarWash'
import { useRoute, useRouter } from 'vue-router'
import { defineComponent, watch, ref, onMounted } from 'vue'
import { ICarWash } from '@/interfaces/ICarWash'
import { useGeolocation } from '@/composables/useGeolocation'
import { useAuth } from '@/composables/useAuth'
import { ERoles } from '@/enums/roles'
import { IBarrel } from '@/interfaces/IBarrel'

export default defineComponent({
    name: 'CarWashDetails',
    components: {
        CircleGraph,
    },
    setup() {
        const { isLoading, activeCarWashStock, fetchCarWash, fetchCarWashList, fetchCarWashStock } =
            useCarWash()
        const { role } = useAuth()
        const { coords, fetchCoordinates } = useGeolocation()
        const router = useRouter()
        const route = useRoute()
        const carWash = ref<ICarWash>()
        const carWashId = ref(+route.params.id)
        const fetchDetails = async () => {
            carWash.value = await fetchCarWash(carWashId.value)
            await fetchCoordinates()
            fetchCarWashList()
            fetchCarWashStock()
        }

        watch(route, async routeValue => {
            if (routeValue.name === 'CarWashDetails') {
                carWashId.value = +route.params.id
                await fetchDetails()
            }
        })

        const getRemainingBarrelAmount = (barrel: IBarrel) => {
            const usedAmount = barrel.capacityUsageInPercents * (barrel.capacity / 100)
            return +usedAmount.toFixed(0)
        }

        const needIntervention = (barrel: IBarrel) => {
            return barrel.capacityUsageInPercents < barrel.expectedCapacityUsageInPercents
        }

        onMounted(async () => {
            if (route.name === 'CarWashDetailsCourier') carWashId.value = +route.params.carWashId
            await fetchDetails()
        })

        return {
            router,
            carWash,
            coords,
            activeCarWashStock,
            isLoading,
            role,
            ERoles,
            getRemainingBarrelAmount,
            needIntervention,
        }
    },
})
